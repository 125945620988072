import { BasicMenuItem } from '@wix/platform-editor-sdk';
import { ExternalLink } from '@wix/document-services-types';

export const createMenuItem = ({
  appBaseUrl,
  isPrivate,
  title,
  pageUriSEO,
}: {
  appBaseUrl: string;
  isPrivate: boolean;
  title: string;
  pageUriSEO: string;
}) => {
  const baseRoute = `https://{domain}/${appBaseUrl}`;
  const fullRoute = `${baseRoute}/{userName}/${pageUriSEO}`;
  const link: ExternalLink = { type: 'ExternalLink', url: fullRoute, target: '_self' };

  return {
    type: 'BasicMenuItem',
    label: title,
    link,
    isVisible: true,
    isVisibleMobile: true,
  } as BasicMenuItem;
};
