import { EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import * as constants from '../../../constants';
import * as menusWrapper from '../../../wrappers/menus';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { createBIService } from '../../../../utils/bi';
import { installSiteApplications } from '../../../platform-api/addApplications';
import { getIsADI, getIsBlogWriterProfilesOnly, getVerticalsApps } from '../../../services/applicationState';
import { getDefinitionsWithWidgetId } from '../services/widget-plugins';
import { addApplicationsToSlots } from '../services/members-area-page';
import { getProfileType, isProfileRegisteredOnlyByBlog, setProfileType } from '../services/blog-writer';
import { createSubPageRoute } from '../services/navigation';
import { IntegrationApplicationWithWidgetId } from '../../types';
import { groupDefinitionsByMethod } from './definitions';
import { ProfileType } from '../../../../types/blog-writer';
import { createMenuItem } from '../services/menu-items';

export const populateDefinitionsWithRoutes = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplicationWithWidgetId[],
) => {
  return Promise.all(
    definitions.map(async (app) => ({
      ...app,
      urlOverride: await createSubPageRoute(editorSDK, app),
    })),
  );
};

export const installApps = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  const definitionsWithRoutes = await populateDefinitionsWithRoutes(editorSDK, definitions);
  const { widgetPlugins, siteApps } = groupDefinitionsByMethod(definitionsWithRoutes);

  if (widgetPlugins.length > 0) {
    await addApplicationsToSlots(editorSDK, widgetPlugins);
  }

  if (siteApps.length > 0) {
    await installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate: false });
  }

  await addLoginBarMenuItems(editorSDK, definitionsWithRoutes);
  await addMembersSubMenuItems(editorSDK, definitionsWithRoutes);
};

const addLoginBarMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  await addMenuItems(editorSDK, definitions, constants.MENU_IDS.LOGIN_MENU_ID);
};

const addMembersSubMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  await addMenuItems(editorSDK, definitions, constants.MENU_IDS.SUB_MENU_ID);
};

const addMenuItems = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplicationWithWidgetId[],
  menuId: string,
) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);
  const filteredDefinitions =
    menuId === constants.MENU_IDS.SUB_MENU_ID
      ? definitions.filter((app) => app.showInMemberMenu)
      : definitions.filter((app) => app.showInLoginMenu);

  const appsData = await Promise.all(
    filteredDefinitions.map((definition) =>
      getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId }),
    ),
  );

  const menuItems = appsData.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }

    return createMenuItem({
      appBaseUrl: membersAreaPage.pageUriSEO,
      isPrivate: !definition.social,
      title: appDataComponent.name,
      pageUriSEO: definition.urlOverride!,
    });
  });

  await menusWrapper.addMenuItems({ editorSDK, menuId, items: menuItems });
};

const maybeSetBlogWriterProfileType = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const hasBlogTriggeredInstall = verticalAppDefId === WIX_BLOG;
  if (!hasBlogTriggeredInstall || getIsADI() || !(await isProfileRegisteredOnlyByBlog(editorSDK))) {
    return;
  }

  const profileType = getIsBlogWriterProfilesOnly() ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  const currentProfileType = await getProfileType(editorSDK);

  if (profileType !== currentProfileType) {
    await setProfileType(editorSDK, profileType);
  }
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false;
  });
  const definitions = getDefinitionsWithWidgetId(integrationAppsToInstall);

  if (definitions.length > 0) {
    await installApps(editorSDK, definitions);
  }

  await maybeSetBlogWriterProfileType(editorSDK, verticalAppDefId);
  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
