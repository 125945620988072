import { ComponentRef, EditorReadyOptions, EditorSDK, PageRef } from '@wix/platform-editor-sdk';

import { allSettled } from '../../../../utils/promises';
import {
  APP_TOKEN,
  getMyAccountInstallDefinition,
  MENU_IDS,
  MY_ACCOUNT_APP_DEF_ID,
  MY_ACCOUNT_WIDGET_ID,
} from '../../../constants';
import { createController } from '../../../wrappers/controllers';
import { createLoginIconsMenu, createLoginMenu, updateMenuItems } from '../../../wrappers/menus';
import { addLoginButton } from '../../../wrappers/components';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import { createMenuItem } from './menu-items';
import { WIX_STORES } from '@wix/app-definition-ids';

const getRefsForLoginMenu = async (editorSDK: EditorSDK) => {
  const [masterRef, headerRef]: [PageRef, ComponentRef] = await allSettled([
    editorSDK.siteSegments.getSiteStructure(APP_TOKEN),
    editorSDK.siteSegments.getHeader(APP_TOKEN),
  ]);
  const controllerRef = await createController(editorSDK, masterRef);

  return { headerRef, controllerRef };
};

const updateGlobalController = async (editorSDK: EditorSDK, controllerRef: ComponentRef) => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'APP',
  });

  await editorSDK.controllers.saveConfiguration('', {
    controllerRef,
    scope: 'APP',
    config: { ...config, isMembersAreaV2: true },
  });
};

export const addLoginMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const isStoresInstallation = options.origin.info?.appDefinitionId === WIX_STORES;
  const { headerRef, controllerRef } = await getRefsForLoginMenu(editorSDK);

  await updateGlobalController(editorSDK, controllerRef);

  await Promise.all([
    createLoginMenu(editorSDK),
    createLoginIconsMenu(editorSDK),
    addLoginButton(editorSDK, controllerRef!, headerRef, isStoresInstallation),
  ]);
};

export const addMembersSubMenu = async (editorSDK: EditorSDK) => {
  await editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: MENU_IDS.SUB_MENU_ID,
  });
};

const addMyAccountMenuItem = async (editorSDK: EditorSDK, options: EditorReadyOptions, menuId: string) => {
  const myAccountDefinition = getMyAccountInstallDefinition(options.origin);
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  const myAccountTPA = await getDataByAppDefId({ editorSDK, appDefinitionId: MY_ACCOUNT_APP_DEF_ID });
  // @ts-expect-error: Platform types are missing, and we have no other source for apps dev center texts
  const myAccountWidget = await myAccountTPA.widgets[MY_ACCOUNT_WIDGET_ID];

  if (!myAccountWidget) {
    throw new Error('My Account widget is not found');
  }

  const menuItem = createMenuItem({
    appBaseUrl: membersAreaPage.pageUriSEO,
    isPrivate: !myAccountDefinition.social,
    title: myAccountWidget.appPage.name,
    pageUriSEO: myAccountDefinition.urlOverride,
  });

  await updateMenuItems({ editorSDK, menuId, items: [menuItem] });
};

const addMyAccountLoginMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.LOGIN_MENU_ID);

const addMyAccountSubMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.SUB_MENU_ID);

export const addMyAccountMenuItemToMembersAreaMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  await addMyAccountLoginMenuItem(editorSDK, options);
  await addMyAccountSubMenuItem(editorSDK, options);
};
